// @ts-nocheck
import React from 'react';

import { _GetStored, _Store } from '@netfront/gelada-react-shared';
import withApollo from 'lib/apollo';
import { useIsAuthenticated } from 'lib/hooks/useIsAuthenticated';
import { useQueryString } from 'lib/hooks/useQueryString';
import Head from 'next/head';

import Button from 'components/Button';
import Footer from 'components/Footer';
import { Grid, GridItem } from 'components/Grid/Grid';
import Header from 'components/Header';

import styles from './indexPage.module.css';

function Index() {
  const isAuthenticated = useIsAuthenticated();
  const { activationDone } = useQueryString();

  const getLink = () => {
    if (isAuthenticated) {
      if (activationDone) {
        return '/avatar-customisation';
      }
      return '/dashboard';
    }
    return '/login';
  };
  return (
    <>
      <Head>
        <title>Rethink my drink</title>
      </Head>
      <main className={styles.main}>
        <div className={styles.Layout}>
          <Header showMenu={isAuthenticated} />
          <section className="h-background--blue-300">
            <Grid>
              <GridItem
                className="h-background--navy l-flex justify-center md-justify-end px md-px--xl pt"
                sizes={['sml-1', 'med-4', 'lrg-4']}
              >
                <img alt="Rethink my drink logo" className="md-p-xl page-icon mt" src="/images/logo-white.svg" />
              </GridItem>

              <GridItem className="md-p-xl h-background--blue-300" sizes={['auto']}>
                <div className="p-t--xl p-b--xl h-container--small md-m-l--none md-p-l">
                  <h1 className="h-color--navy">Welcome to Rethink My Drink</h1>
                  <p className="m-b--large font-med">
                    This website has been specially designed by health professionals to help you gain control of your alcohol consumption.
                    The program is for anyone who wishes to change their relationship with alcohol, from heavy drinkers to more moderate or
                    ‘social drinkers’. There are four modules to complete, with unique content, exercises, activities and online tools. The
                    program is based on the “Stages of Change” model, which is used by health professionals and psychologists to describe
                    the stages involved in making long-lasting changes to habits and behaviours. Watch the video below to learn more about
                    the model.
                  </p>
                  <div className="m-b--large font-med text-align-center c-embed-snippet">
                    <iframe
                      allow="autoplay"
                      allowFullScreen="true"
                      frameBorder="0"
                      height="315"
                      src="https://player.vimeo.com/video/813915838?h=199298329f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      style={{ margin: '0 auto' }}
                      title="main-video"
                      width="560"
                    />
                  </div>
                  <p className="m-b--large font-med">
                    As the video shows, changing your relationship with alcohol is rarely a simple task. We hope that the Rethink My Drink
                    program will make this process a little easier and more likely to lead to long-term success in your drinking goals.
                    Before you begin, you will be asked to customise your own personalised ‘Health Expert’, who will guide you through each
                    step of the program. If you think you are ready to take the first step towards a healthier, more balanced lifestyle,
                    click the button below to get started!
                  </p>
                  <div className="l-flex">
                    <Button className="m-l--auto" component="link" link={{ href: getLink() }} style="squared">
                      <span>Get started</span>
                      <img alt="readmore" className="m-l--small" src="/images/chevron-right.svg" />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </Grid>
          </section>
          {!isAuthenticated && (
            <section className="h-background--navy p-t--xl p-b--xl bg-watermark-blue">
              <div className="h-container l-flex justify-center p-t--xl p-b--xl">
                <div>
                  <h3 className="h1 h-color--white m-b--xl text-center">Get access today</h3>
                  <div className="l-flex justify-center">
                    <Button className="m-r w-50" color="secondary-inverse" component="link" link={{ href: '/login' }} style="squared">
                      Login
                    </Button>
                  </div>
                </div>
              </div>
            </section>
          )}
          <section className="h-background--white p-t--xl p-b--xl">
            <div className="h-container l-flex justify-center p-t--xl p-b--xl">
              <div>
                <h3 className="h1 h-color--navy m-b--xl text-center">Partners</h3>

                <Grid>
                  <GridItem className="l-flex align-center justify-center" sizes={['sml-1', 'med-1', 'lrg-2']}>
                    <img alt="UNSW logo" className="w-100 m-w-600" src="/images/CHeBA-UNSW-lock.png" />
                  </GridItem>
                  <GridItem className="l-flex align-center justify-center" sizes={['sml-1', 'med-1', 'lrg-2']}>
                    <img alt="Matilda centre logo" className="w-100 m-w-200" src="/images/matilda-centre-mono.png" />
                  </GridItem>
                </Grid>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default withApollo(Index, { useTemporaryToken: true });
