import { ReactNode } from 'react';
import styles from './Grid.module.css';
import cx from 'classnames';

interface GridProps {
  children?: ReactNode;
  className?: string;
}

const Grid = ({ children, className }: GridProps) => <div className={cx(styles['c-grid__container'], className)}>{children}</div>;

interface GridItemProps {
  children?: ReactNode;
  className?: string;
  sizes?: string[];
}

const GridItem = ({ children, className, sizes }: GridItemProps) => {
  const buildSizes = sizes.map((size) => styles[`c-grid__item--${size}`]);

  return (
    <div className={cx(styles['c-grid__item'], buildSizes, className)}>
      {/* <div className={cx(className, styles['c-grid__inner'])}> */}
      {children}
      {/* </div> */}
    </div>
  );
};

export { Grid, GridItem };
